<template>
  <div>
    <Editor/>
  </div>
</template>

<script>
import Editor from "@/components/Events/Editor/Editor.vue"    
export default {
    components: {
        Editor
    }
}
</script>

<style>

</style>